
.navigation-chevron {
  border-color: $datepicker__muted-color;
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 6px;
  &--disabled, &--disabled:hover {
    border-color: $datepicker__navigation-disabled-color;
    cursor: default;
  }
}

.triangle-arrow {
  margin-left: - $datepicker__triangle-size * 0.5;
  position: absolute;
  width: 0;

  &::before,
  &::after {
    box-sizing: content-box;
    position: absolute;
    border: $datepicker__triangle-size solid transparent;
    height: 0;
    width: 1px;
    content: "";
    z-index: -1;
    left: -$datepicker__triangle-size;
  }

  &::before {
    border-bottom-color: $datepicker__border-color;
  }
};

.triangle-arrow-up {
  @extend .triangle-arrow;

  top: 0;
  margin-top: -$datepicker__triangle-size;

  &::before,
  &::after {
    border-top: none;
    border-bottom-color: $datepicker__background-color;
  }

  &::after {
    top: 0;
  }

  &::before {
    top: -1px;
    border-bottom-color: $datepicker__border-color;
  }
};

.triangle-arrow-down {
  @extend .triangle-arrow;

  bottom: 0;
  margin-bottom: -$datepicker__triangle-size;

  &::before,
  &::after {
    border-bottom: none;
    border-top-color: #fff;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    bottom: -1px;
    border-top-color: $datepicker__border-color;
  }
};