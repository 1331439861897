@font-face {
    font-family: "Poppins";
    src: url("../../assets/font/Poppins.ttf") format("truetype");
}

*, ::before, ::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Poppins', serif;
    background-color: white;
    overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
    .intercom-launcher {
        bottom: 85px !important;
    }

    div[class^="intercom-frame"], div[class^="intercom-with-namespace"]{
        bottom: 85px !important;
    }
}